body{
  content:'';
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background:rgb(38, 39, 38)  ;
  z-index:-1;
}

.games{
  background: url('./Assets/game.png');
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  scrollbar-width: none;
}
.scroll-container ::-webkit-scrollbar{
  display:none;
}
.mines{
  background: url('./Assets/minesbg.png');
  background-size: cover;
}
.crash-container{
  background: linear-gradient(to bottom, rgb(0, 133, 40), rgb(2, 5, 44));
}


.bubbles{
  position: fixed;
  display: flex;
}
.bubbles span{
  z-index: 0;
  bottom: 0px;
  width:10px;
  height: 10px;
  background: rgb(255, 255, 255);
  margin: 0 4px;
  border-radius:50%;
  animation: animate 15s linear infinite;
  animation-duration: calc(130s /var(--i));
}

@keyframes animate{
  0%{
    transform: translateY(100vh) scale(0);
  }
  100%{
    transform: translateY(-10vh) scale(1);
    opacity: 0;
  }
}